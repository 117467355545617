import {request} from "../utils";

// 获取保障车信息列表
export const getInfoList = params => request.get('/api/support_vehicle_info/', {params})

//添加保障车信息
export const postInfoList = data => request.post('/api/support_vehicle_info/', data)

//修改保障车信息
export const editInfoList = data => request.patch(`/api/support_vehicle_info/${data.id}/`, data)

//删除保障车信息
export const deleteInfoList = id => request.delete(`/api/support_vehicle_info/${id}/`)

// 获取保障车类型列表
export const getTypeList = params => request.get('/api/support_vehicle_type/', {params})

//添加保障车类型
export const postTypeList = data => request.post('/api/support_vehicle_type/', data)

//修改保障车类型
export const editTypeList = data => request.patch(`/api/support_vehicle_type/${data.id}/`, data)

//删除保障车类型
export const deleteTypeList = id => request.delete(`/api/support_vehicle_type/${id}/`)

// 获取保障路线数据
// export const add_path = data => request.post('/api/support_vehicle_path/', data)

// 获取保障路线数据
export const get_path = params => request.get('/api/airport_data_path/', { params })

// 获取排班表数据
export const getScheduler=params=>request.get('/api/support_vehicle_scheduling/',{params})
// 添加排班表数据
export const addScheduler=data=>request.post('/api/support_vehicle_scheduling/',data)
// 修改排班表数据
export const editScheduler=data=>request.patch(`/api/support_vehicle_scheduling/${data.id}/`,data)
// 删除排班表数据
export const delScheduler=id=>request.delete(`/api/support_vehicle_scheduling/${id}/`)

// 获取保障车停靠路线
export const getStopRoute=data => request.post('/api/get_range_scheduling_path/',data)

// 获取机场信息
export const getAirport=()=>request.get('/api/airport/?pagesize=999')

// 获取机场保障的全部路径数据  // 获取机场保障节点
export const get_support_vehicle_all_path_data = params =>request.get('/api/support_vehicle_data_path/', { params })

// 添加机场保障节点
export const add_support_vehicle_info= data =>request.post(`/api/support_vehicle_data_path/`,data)
// 修改机场保障节点
export const edit_support_vehicle_info= data =>request.patch(`/api/support_vehicle_data_path/${data.id}/`,data)
// 删除机场保障节点
export const del_support_vehicle_info= id =>request.delete(`/api/support_vehicle_data_path/${id}`)


// 获取甘特图数据(机场保障排班表)
export const get_gantt_data = params => request.get('/api/scheduling_gantt/',{params})
