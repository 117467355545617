<template>
    <div>
        <v-container>
            <div id="cesiumContainer"></div>
        </v-container>
    </div>
</template>
<script>

    import { get } from "../../api/tracks";
    // import da from '/support_vetcel'
    import { add_path, get_path } from "../../api/airport_security";
    import { get_support_vehicle_all_path_data } from "../../api/airport_security";
    import { get_car_path } from "../../api/pathfinding";

    export default {
        name: 'flight_path_preview',
        data: () => ({
            layout: null
        }),
        mounted() {
            this.layout = this.$route.meta.use_layout
            // this.$route.params.id
            // for (let i in da ) {
            //     for (let ii in da[i]) {
            //         add_path({
            //             lat: da[i][ii][0],
            //             lng :da[i][ii][1],
            //             type: da[i][ii][2],
            //             attach: da[i][ii][2] == 2 ? da[i][ii][3] : '0',
            //             group: i,
            //             order: ii
            //         })
            //     }
            // }
            this.$nextTick(() => {
                const viewer = new this.Cesium.Viewer("cesiumContainer", {
                    infoBox: false,
                    selectionIndicator: false,
                    shadows: true,
                    shouldAnimate: true,
                });

                viewer._cesiumWidget._creditContainer.style.display = 'none'
                const handle = new this.Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)

                var ellipsoid=viewer.scene.globe.ellipsoid;
                handle.setInputAction(movement => {
                    var cartesian=viewer.camera.pickEllipsoid(movement.position, ellipsoid);
                    if(cartesian){
                        let cartographic=viewer.scene.globe.ellipsoid.cartesianToCartographic(cartesian);
                        //将地图坐标（弧度）转为十进制的度数
                        let lat_String=this.Cesium.Math.toDegrees(cartographic.latitude).toFixed(8); //经
                        let log_String=this.Cesium.Math.toDegrees(cartographic.longitude).toFixed(8);//纬
                        let alti_String=(viewer.camera.positionCartographic.height/1000).toFixed(2);//高
                        console.log(lat_String, log_String)
                    }
                }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK)

                // 获取自动寻路路径,并显示
                get_car_path().then(res => {
                    const data = []
                    res.data.forEach(x => {
                        data.push(parseFloat(x))
                    })
                    const entities = viewer.entities.add({
                        polyline: {
                            show: true,
                            positions: this.Cesium.Cartesian3.fromDegreesArray(data),
                            material: this.Cesium.Color.BLUE,
                            width: 1
                        }
                    })
                })
                // 自定义数据源
                const customDataSource = new this.Cesium.CustomDataSource('customDataSource')

                // 获取机场路径
                get_path({ pagesize: 9999 }).then(res => {
                // get_support_vehicle_all_path_data({ pagesize: 9999 }).then(res => {
                    const data = res.results
                    const data_group = {}
                    // 整理数据, 将数据分组
                    for (let i of data) {
                        if (i.group in data_group) {
                            data_group[i.group].points.push(i)
                            // data_group[i.group].show_label =  data_group[i.group].show_labe ?  data_group[i.group].show_labe : i.type == 1  || i.type == 3// 如果是停机位显示label
                            data_group[i.group].show_label =  true
                        }else {
                            data_group[i.group] = {
                                show_label: i.type == 1, // 如果是停机位显示label
                                points: [i],
                                // label: i.name ? i.name : i.group
                                label: i.group
                            }
                        }
                    }
                    // 将每组数据用order 进行排序
                    for( let i in data_group) {
                        data_group[i].points.sort((a, b) => {
                            return a.order - b.order
                        })
                    }
                    const cesium_data = []
                    // 将数据变为cesium 结构坐标
                    let index = 0
                    for (let i in data_group) {
                        cesium_data[index] = []
                        for (let ii of data_group[i].points) {
                            cesium_data[index].push(parseFloat(ii.lng))
                            cesium_data[index].push(parseFloat(ii.lat))
                        }
                        const m_color = this.Cesium.Color.fromRandom({alpha: 1})
                        const entities = new this.Cesium.Entity({
                            // position: this.Cesium.Cartesian3.fromDegrees(cesium_data[index][cesium_data[index].length - 2], cesium_data[index][cesium_data[index].length - 1]),
                            position: this.Cesium.Cartesian3.fromDegrees(cesium_data[index][cesium_data[index].length/2%2 == 0 ? cesium_data[index].length / 2  : cesium_data[index].length/2 - 1 ],cesium_data[index][cesium_data[index].length/2%2 == 0 ? cesium_data[index].length/2 + 1 : cesium_data[index].length/2  ]),
                            label: {
                                show: data_group[i].show_label,
                                text: data_group[i].label.toString(),
                                font: '12px sans-serif',
                                fillColor: m_color
                            },
                            polyline: {
                                show: true,
                                positions: this.Cesium.Cartesian3.fromDegreesArray(cesium_data[index]),
                                material: m_color,
                                width: 1
                            }
                        })
                        customDataSource.entities.add(entities)
                        index++
                    }
                    // for (let i of cesium_data) {
                    //     const entities = new this.Cesium.Entity({
                    //         label: {
                    //           show: true,
                    //           text: '1'
                    //         },
                    //         polyline: {
                    //             show: true,
                    //             positions: this.Cesium.Cartesian3.fromDegreesArray(i),
                    //             material: this.Cesium.Color.RED,
                    //             width: 1
                    //         }
                    //     })
                    //     customDataSource.entities.add(entities)
                    // }
                    viewer.dataSources.add(customDataSource)
                })
                // 获取航线
                get(1).then(res => {
                    console.log('hello airport path')
                    if (res.code) {
                        const flightData = res.data
                        for (let i in flightData) {
                            flightData[i].latitude = parseFloat(flightData[i].latitude)
                            flightData[i].longitude = parseFloat(flightData[i].longitude)
                            flightData[i].height = parseFloat(flightData[i].height)
                        }
                        const timeStepInSeconds = 30;
                        const totalSeconds = timeStepInSeconds * (flightData.length - 1);
                        const start = this.Cesium.JulianDate.fromIso8601("2020-03-09T23:10:00Z");
                        const stop = this.Cesium.JulianDate.addSeconds(start, totalSeconds, new this.Cesium.JulianDate());
                        viewer.clock.startTime = start.clone();
                        viewer.clock.stopTime = stop.clone();
                        viewer.clock.currentTime = start.clone();
                        viewer.timeline.zoomTo(start, stop);
                        viewer.clock.multiplier = 50;
                        viewer.clock.shouldAnimate = true;

                        const positionProperty = new this.Cesium.SampledPositionProperty();
                        for (let i = 0; i < flightData.length; i++) {
                            const dataPoint = flightData[i];

                            // Declare the time for this individual sample and store it in a new JulianDate instance.
                            const time = this.Cesium.JulianDate.addSeconds(start, i * timeStepInSeconds, new this.Cesium.JulianDate());
                            const position = this.Cesium.Cartesian3.fromDegrees(dataPoint.longitude, dataPoint.latitude, dataPoint.height);
                            // Store the position along with its timestamp.
                            // Here we add the positions all upfront, but these can be added at run-time as samples are received from a server.
                            positionProperty.addSample(time, position);

                            viewer.entities.add({
                                position: position,
                                point: { pixelSize: 0, color: this.Cesium.Color.RED }
                            });
                        }

                        const airplaneEntity = viewer.entities.add({
                            availability: new this.Cesium.TimeIntervalCollection([ new this.Cesium.TimeInterval({ start: start, stop: stop }) ]),
                            position: positionProperty,
                            // point: { pixelSize: 30, color: this.Cesium.Color.GREEN },
                            path: new this.Cesium.PathGraphics({ width: 0 }),
                            orientation: new this.Cesium.VelocityOrientationProperty(positionProperty),
                            model : {
                                uri : ' https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/CesiumMilkTruck.glb',
                                minimumPixelSize : 64
                            },
                        });
                      viewer.zoomTo(
                          viewer.entities,
                          new this.Cesium.HeadingPitchRange(0, this.Cesium.Math.toRadians(-90))
                      );

                    }
                })
            })
        }
    }
</script>
<style>
    html,
    body {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    #app,#cesiumContainer {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
</style>
